.maintenance-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  text-align: center;
}
.maintenance-container h1 {
  margin-bottom: 20px;
}
.maintenance-container span {
  color: #1877F2;
  font-weight: bold;
}