@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
* {
    margin: 0 auto;
    color: white;
    box-sizing: border-box;
    position: relative;
}
body {
    background-color: black;
}
a {
    color: unset;
    text-decoration: none;
}

.content-container {
    width: 100vw;
    min-height: 100vh;
    padding-top: 90px;
    .content {
        width: 60%;
        margin-top: 60px;
        padding: 30px;
        border: 1px solid white;
        background: rgb(2, 0, 36);
        background: radial-gradient(circle, rgb(0 0 0 / 72%) 82%, rgba(0, 212, 255, 0) 100%);
        .title {
            margin-bottom: 20px;
        }
        .text {
            margin: 20px 0;
            p {
                margin: 10px 0;
                line-height: 25px;
            }
        }
    }
}
.line-divider {
    position: relative;
    width: 80%;
    height: 0.5px;
    background-color: white;
    display: flex;
    align-items: center;
    img {
        width: 40px;
    }
}

.home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: black;
    color: white;
    &::before {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background-image: url("./assets/cat-background.webp");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .background {
        background: rgb(2, 0, 36);
        background: radial-gradient(circle, rgb(0 0 0 / 72%) 56%, rgba(0, 212, 255, 0) 100%);
        position: relative;
    }
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 45px 0;
        position: relative;
        h1 {
            margin: 15px;
            font-family: "Cormorant Garamond", serif;
            font-weight: 300;
            font-style: normal;
            font-size: 55px;
            text-align: center;
        }
        h2 {
            margin-top: 10px;
            font-family: "Roboto Condensed", sans-serif;
            font-optical-sizing: auto;
            font-weight: 500;
            font-style: normal;
            font-size: 20px;
            text-align: center;
        }
        p {
            margin: 10px;
            font-family: "Roboto Condensed", sans-serif;
            font-optical-sizing: auto;
            font-weight: 200;
            font-style: normal;
            font-size: 20px;
            text-align: center;
        }
    }
}

.about-container {
    position: relative;
    &::before {
        content: " ";
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background-image: url("./assets/background2.jpg");
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: right;
    }
    .team {
        display: flex;
        flex-wrap: wrap;
        h3 {
            width: 100%;
            margin-bottom: 30px;
        }
        .card {
            min-width: calc(97% / 4);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h4 {
                font-size: 20px;
            }
        }
    }
}
@media (max-width: 1150px) {
    .content-container {
        .content {
            width: 80%;
        }
    }
}
@media (max-width: 768px) {
    .about-container::before {
        background-size: 150%;
    }
    .content-container {
        .content {
            width: 90%;
        }
    }
}
@media (max-width: 428px) {
    .about-container::before {
        background-size: 200%;
        background-position: left;
    }
}

.donate-container {
    &::before {
        content: " ";
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        background-image: url("./assets/background3.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: -50px;
    }
}
@media (max-width: 768px) {
    .donate-container::before {
        background-size: cover;
        background-position-y: -120px;
    }
}

@media (max-width: 490px) {
    .news-container {
        .content {
            padding: 20px 0;
        }
    }
}

.nav-container {
    position: absolute;
    top: 0;
    color: white;
    z-index: 999;
    padding: 20px;
    .hamburger-react {
        display: none;
    }
    ul {
        display: flex;
        font-weight: 200;
        li {
            margin: 0 20px;
            text-decoration: none;
            list-style: none;
            padding-bottom: 10px;
            cursor: pointer;
            font-size: 30px;
            display: flex;
            justify-content: center;
            position: relative;
            width: 80px;
            font-family: "Roboto Condensed", sans-serif;
            font-style: normal;
            font-weight: 100;
            img {
                display: none;
                position: absolute;
                bottom: -15px;
                left: 38%;
            }
            &:hover {
                border-bottom: 2px solid white;
                font-weight: 600;
                img {
                    display: block;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .nav-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 0;
        .hamburger-react {
            display: block;
            margin: 20px;
            z-index: 999;
        }
        ul {
            display: none;
            margin: 0;
            padding: 0;
        }
        .nav-open {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            width: 100%;
            background-color: #000000b5;
            li {
                margin-bottom: 10px;
            }
        }
    }
}


@import url("./components/_banner.scss");
@import url("./components/_post.scss");
