.toggle-banner {
    width: 40px;
    position: absolute;
    z-index: 9999;
    top: 10px;
    right: 20px;
    cursor: pointer;
}
.bounce {
    animation: bounce 2s ease infinite;
}
@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
