.post-container {
    .card {
        border: 1px solid white;
        margin: 30px 0;
        background: #101010;
        .card-title {
            border-bottom: 1px solid white;
            padding: 10px 20px;
        }
        .card-content {
            padding: 10px 20px;
            display: flex;
            .card-image{
                width: 40%;
            }
        }
    }
}
